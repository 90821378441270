import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"color":"blue darken-4","dark":"","app":"","fixed":"","clipped-left":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.clickOnMenuIcon.apply(null, arguments)}}}),_c('img',{staticClass:"hamp-toolbar-logo",attrs:{"src":require("@/assets/white.png")}}),_c(VToolbarTitle,[_c('router-link',{attrs:{"to":_vm.homepath,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{on:{"click":navigate}},[_vm._v(_vm._s(_vm.appName))])]}}])})],1),_c(VSpacer),(_vm.user)?_c(VToolbarTitle,[_c('router-link',{attrs:{"to":_vm.accountpath,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{on:{"click":navigate}},[_vm._v(_vm._s(_vm.firstName)+" "+_vm._s(_vm.lastName))])]}}],null,false,1723379124)})],1):_vm._e(),(!_vm.user)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.login.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-account-circle")])],1):_vm._e(),(_vm.user)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-eject")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }