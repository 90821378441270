import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticStyle:{"transition-duration":"0.1s"},attrs:{"width":"width","app":"","fixed":"","clipped":_vm.$vuetify.breakpoint.lgAndUp},model:{value:(_vm.drawerOpen),callback:function ($$v) {_vm.drawerOpen=$$v},expression:"drawerOpen"}},_vm._l((_vm.filteredGroups),function(group){return _c(VList,{key:group.title},[_c(VListGroup,{attrs:{"prepend-icon":group.icon,"append-icon":_vm.appendIcon(group)},model:{value:(group.active),callback:function ($$v) {_vm.$set(group, "active", $$v)},expression:"group.active"}},[_c(VListItem,{staticStyle:{"padding-left":"0","font-weight":"700"},attrs:{"slot":"activator","to":group.to},slot:"activator"},[_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"transition-duration":"0.1s"}},[_vm._v(_vm._s(group.title))])],1)],1),_vm._l((group.items),function(item){return _c(VListItem,{key:item.title,attrs:{"to":item.to}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-circle-medium")])],1),_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.title)}})],1)})],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }